<template>
  <div>
    <v-card class="my-2">
      <v-card-title>{{ user.first_name }} {{ user.last_name }} ID: {{user.id }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="(value, name) in user"
            cols="4"
            v-bind:key="name"
          >
            <span v-if="typeof value !== 'object'">{{ name }}: {{value}}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UserAdminInfo",
  props: {
    user: { type: Object, required: true },
  },
  data: () => ({
  }),

  methods: {
  },
};
</script>>