<template>
    <v-container v-if="item">
        <v-row>
            <v-col>
                <v-text-field label="Názov hodinovej stránky" v-model="item.title" />
            </v-col>
        </v-row>
        <v-tabs v-model="tabs" ceneterd>
            <v-tab v-for="index in 12" v-bind:key="index">
                Hodina {{ index }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_1_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_1_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_2_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_2_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_3_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_3_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_4_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_4_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_5_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_5_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_6_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_6_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_7_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_7_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_8_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_8_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_9_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_9_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_10_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_10_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_11_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_11_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card>
                    <v-row dense>
                        <v-col>
                            <v-text-field label="Nazov" v-model="item.clock_12_title" />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <editor v-model="item.clock_12_text" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>

        </v-tabs-items>

        <v-row>
            <v-col>
                <v-btn color="primary" @click="updateItem()">Uložiť</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import Editor from "../../components/Editor.vue";

const apiService = new APIService();

export default {
    name: "admin-clocks",

    components: {
        Editor
    },

    data: () => ({
        item: null,
        tabs: 0
    }),

    mounted: function () {
        this.getItem();
    },

    methods: {
        getItem() {
            apiService.getClocksPage().then(response => {
                this.item = response;
            })
        },

        updateItem() {
            apiService.updateClocksPage(this.item).then((response) => {
                this.item = response;
            });
        }
    },
};
</script>